import React from "react"

import Layout from "../components/layout"
import SEO from "../utils/seo"

const NotFoundPage = () => (
  <Layout title="Vaja :(">
    <SEO title="404: Not found" />
    <p>No hem trobat la pàgina que estàs cercant.</p>
  </Layout>
)

export default NotFoundPage
